(function($){
    // Sticky header
    var scroll = $(window).scrollTop();
    if (scroll > 50) {
        $("header").addClass("sticky");
    } else {
        $("header").removeClass("sticky");
    }
    $(window).on('scroll', function (event) {
        var scroll = $(window).scrollTop();
        if (scroll > 50) {
            $("header").addClass("sticky");
        } else {
            $("header").removeClass("sticky");
        }
    });

    // Menu trigger
    $('.menu-icon').on('click', function () {
        $('html').toggleClass('menu-open');
        $('header').toggleClass('menu-open');
        $(this).closest('.menu-trigger').toggleClass('menu-open');
        $('.header-menu-area').toggleClass('menu-open');
    });

    $('.scroll-menu > a').on('click', function(){
        $('html').removeClass('menu-open');
        $('.menu-icon').closest('.menu-trigger').removeClass('menu-open');
        $('.header-menu-area').removeClass('menu-open');
        $(this).closest('ul').slideUp();
        $(this).closest('li.menu-item-has-children').removeClass('opened');
    });

    // Submenu
    $('.menu-item-has-children > a').on('click', function (e) {
        $('.menu-item-has-children').find('a').not(this).closest('li').find('.sub-menu').slideUp();
        $('li.menu-item-has-children').find('a').not(this).closest('li').removeClass('opened');
        $(this).closest('li').toggleClass('opened');
        $(this).closest('li').find('.sub-menu').slideToggle(400);
        e.stopPropagation();
        e.preventDefault();
	});

    // Menu scrollbar
    const menuScroll = document.querySelector('.menu-scroll');
    const ps = new PerfectScrollbar(menuScroll);

    // Scrolltop
    $('.to-top').on('click',function(){
        $('html, body').animate({scrollTop:0}, 'slow');
        return false;
    });

    // Button wrap in span
    $('.btn, .wp-element-button').each(function(){
        $(this).wrapInner('<span></span>');
    });

    // First section willl have long padding
    $('main').find('section').first().not('.intro-area').addClass('top-section');

    // Challanges readmore
    $('.challanges-more-toggle').each(function(){
        let moreText = $(this).text();
        let lessText = $(this).data('less');
        $(this).on('click', function(){
            $(this).closest('.challenge-content-area').find('.challanges-more-content').slideToggle(200);
            $(this).text($(this).hasClass('active')? moreText : lessText).toggleClass('active');
        });
    });
    
    $('.consortium-more-toggle').each(function(){
        let moreText = $(this).text();
        let lessText = $(this).data('less');
        $(this).on('click', function(){
            $(this).closest('.consortium-banner-content').find('.consortium-more-content').slideToggle(200);
            $(this).text($(this).hasClass('active')? moreText : lessText).toggleClass('active');
        });
    });

    // Consortium readmore
    $('.consortium-description-more-toggle').each(function(){
        let moreText = $(this).text();
        let lessText = $(this).data('less');
        $(this).on('click', function(){
            $(this).closest('.consortium-item-more-description').find('.consortium-item-more-description-content').slideToggle(200);
            $(this).text($(this).hasClass('active')? moreText : lessText).toggleClass('active');
        });
    });
    $('.consortium-role-more-toggle').each(function(){
        let moreText = $(this).text();
        let lessText = $(this).data('less');
        $(this).on('click', function(){
            $(this).closest('.consortium-item-more-role').find('.consortium-item-more-role-content').slideToggle(200);
            $(this).text($(this).hasClass('active')? moreText : lessText).toggleClass('active');
        });
    });

    // Smoothscroll
    $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').on('click', function(event) {
        if ( location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')  &&  location.hostname == this.hostname ) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // console.log(target);
            if (target.length) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: (target.offset().top - $('header').outerHeight())
                }, 400);
                menuScroll.scrollTop = 0;
                ps.update();
            }
        }
    });

    // Scroll top
    $('.mouse-up').on('click', function(e){
        e.preventDefault();
        $('html, body').animate({scrollTop:0}, 400);
    });
    let winHeight = $(window).outerHeight();
    let headerHeight = $('header').outerHeight();
    let scrollHeight = winHeight - headerHeight;
    $('.mouse-down').on('click', function(e){
        e.preventDefault();
        $('html, body').animate({scrollTop:scrollHeight}, 400);
    });


    var scroll = $.cookie('scroll');
    if(scroll){
        scrollToID(scroll, 0);
        $.removeCookie('scroll');
    }

    // Handle event onclick, setting the cookie when the href != #
    $('.button-with-id a').on('click', function(e){
        e.preventDefault();
        var id = $(this).data('id');
        var href = $(this).attr('href');
        if(href === '#'){
            scrollToID(id, 0);
        }else{
            $.cookie('scroll', id);
            window.location.href = href;
        }
    });

    // scrollToID function
    function scrollToID(id, speed) {
        var offSet = 40;
        var obj = $('#' + id);
        if(obj.length){
            var offs = obj.offset();
            var targetOffset = offs.top - offSet;
            $('html,body').animate({ scrollTop: targetOffset }, speed);
            $.removeCookie('scroll');
        }
    }


    // Custom select
    if($('.custom-select').length){
        $('select.custom-select').selectric();
    }
    $('select.select-to-download').on('change', function () {
		var url = $(this).val(); // get selected value
		if (url) { // require a URL
			// window.location = url; // redirect
            window.open(url, '_blank');
		}
		return false;
	});



    // AOS animation
    AOS.init({
        once: true,
        anchorPlacement: 'top-bottom',
        offset: 120,
        duration: 800,
    });


})(jQuery);

// Mouse wheel passive scroll
// $.event.special.touchstart = {
//     setup: function(_, ns, handle) {
//         this.addEventListener("touchstart", handle, {
//             passive: !ns.includes("noPreventDefault")
//         });
//     }
// };
// $.event.special.touchmove = {
//     setup: function(_, ns, handle) {
//         this.addEventListener("touchmove", handle, {
//             passive: !ns.includes("noPreventDefault")
//         });
//     }
// };
// $.event.special.wheel = {
//     setup: function(_, ns, handle) {
//         this.addEventListener("wheel", handle, {
//             passive: true
//         });
//     }
// };
// $.event.special.mousewheel = {
//     setup: function(_, ns, handle) {
//         this.addEventListener("mousewheel", handle, {
//             passive: true
//         });
//     }
// };